import { makeStyles } from '@material-ui/core/styles'
import { BREAKPOINTS, COLORS, FONTS } from '../constants'

export const styles = makeStyles(theme => ({
  seoHeading: {
    fontSize: '16px !important',
    fontFamily: 'Graphik Light !important',
    letterSpacing: '0.00938em !important',
    lineHeight: '1.75 !important',
    marginBottom: '0px !important',
    marginTop: '10px !important',
    fontWeight: 400,
  },
  searchButton: {
    maxWidth: '25%',
    minWidth: '75px',
    backgroundColor: '#FAFBFD',
    border: 'none',
    position: 'relative',
  },
  searchIcon: {
    maxWidth: '100%',
    width: 20,
    position: 'absolute',
    right: 20,
    top: -10,
  },
  scrollButton: {
    position: 'fixed',
    zIndex: 99,
    right: '1.25rem',
    bottom: '1.25rem',
    width: '4rem',
    height: '4rem',
    cursor: 'pointer',
    outline: 'none',
    textAlign: 'center',
    fontSize: 'large',
    fontWeight: 600,
    fontFamily: FONTS.Graphik.GraphikRegular,
    borderWidth: 0,
    borderRadius: '100%',
    color: COLORS.white,
    backgroundColor: COLORS.black,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  hidden: {
    display: 'none',
  },
  searchWrapper: {
    display: 'flex',
    minWidth: 335,
    width: '100%',
    backgroundColor: '#FAFBFD',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: 20,
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      marginLeft: 0,
      marginTop: 40,
      width: '100%',
    },
  },
  [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
    searchForm: {
      maxWidth: '350px',
    },
  },
  headerRightSide: {
    display: 'flex',
    justifyContent: 'space-between',

    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      flexDirection: 'column',
      width: '100%',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginBottom: 60,
    alignItems: 'baseline',
    [`@media (max-width: ${BREAKPOINTS.xmd}px)`]: {
      flexDirection: 'column',
    },
  },
  theChirpyest: {
    marginBottom: 0,
  },
  mainGrid: {
    [`@media (max-width: ${BREAKPOINTS.xmd}px)`]: {
      width: '100%',
      margin: 0,
      '& > .MuiGrid-item': {
        padding: 0,
      },
    },
  },
  link: {
    textDecoration: 'none',
    color: '#000000DE',
  },
  noData: {
    textAlign: 'center',
    color: COLORS.lightGrey,
  },
  leftSide: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      width: '100%',
    },
    [`& nav.MuiBreadcrumbs-root`]: {
      marginBottom: 15,
    },
    [`& li.MuiBreadcrumbs-li a`]: {
      fontSize: '14px !important',
      fontWeight: 300,
      color: COLORS.black,
      letterSpacing: '0.38px',
      lineHeight: '15px',
      cursor: 'pointer',
      fontFamily: 'Graphik Light',
    },
    [`& li.MuiBreadcrumbs-separator`]: {
      fontSize: '14px !important',
      fontWeight: 300,
      letterSpacing: '0.38px',
      lineHeight: '15px',
      fontFamily: 'Graphik Light',
      color: COLORS.black,
      marginTop: 5,
    },
    [`& li.MuiBreadcrumbs-li p`]: {
      height: 27.43,
      fontSize: '14px !important',
      fontWeight: 300,
      letterSpacing: '0.38px',
      lineHeight: '31px',
      fontFamily: 'Graphik Light',
      color: COLORS.black,
    },
  },
  description: {
    width: '100%',
    marginTop: '8px',
    marginBottom: 0,
  },
}))
