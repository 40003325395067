import { makeStyles } from '@material-ui/core/styles'
import { BREAKPOINTS, COLORS, FONTS } from '../../constants'

export const styles = makeStyles(theme => ({
  gridItem: {
    position: 'relative',
    cursor: 'pointer',
    marginBottom: 100,
    [`@media (max-width: ${BREAKPOINTS.xmd}px)`]: {
      marginBottom: 60,
    },
  },
  errorClass: {
    objectFit: 'scale-down',
    width: '50%',
    border: '1px solid #FFF',
    height: 232,
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      height: 188,
    },
  },
  imageWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
  },
  image: {
    width: '33%',
    height: 232,
    objectFit: 'cover',
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      height: 188,
    },
  },
  errorImage: {
    width: '33%',
    height: 232,
    objectFit: 'none',
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      height: 188,
    },
  },
  description: {
    color: COLORS.lightGrey,
    marginBottom: 5,
    marginTop: 20,
    [`@media (max-width: ${BREAKPOINTS.xmd}px)`]: {
      paddingRight: 20,
      paddingLeft: 20,
    },
  },
  mainGrid: {
    [`@media (max-width: ${BREAKPOINTS.xmd}px)`]: {
      margin: 0,
      '& > .MuiGrid-item': {
        padding: 0,
      },
    },
  },
  edgeSpaces: {
    [`@media (max-width: ${BREAKPOINTS.xmd}px)`]: {
      paddingRight: 20,
      paddingLeft: 20,
    },
    marginBottom: '20px !important',
  },
  imageHalfWidth: {
    width: '50%',
    objectFit: 'cover',
    border: '1px solid #FFF',
    height: 232,
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      height: 188,
    },
  },
  blurb: {
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 3,
    fontFamily: FONTS.Graphik.GraphikLight + ' !important',
    fontSize: '1rem !important',
    fontWeight: 300,
    [`@media (max-width: ${BREAKPOINTS.xmd}px)`]: {
      paddingLeft: 20,
    },
  },
}))
